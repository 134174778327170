import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { Card } from '@/components/ui/card.tsx';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Button } from '@/components/ui/button.tsx';
import { DataTable } from '@/components/data-table.tsx';
import { columns } from '@/routes/app/organizations/sponsors/single/columns.tsx';
import { columns as userColumns } from '@/routes/app/users/columns.tsx';
import CollateralForm from '@/components/forms/collateral-form.tsx';
import OrganizationDetailsCard from '@/routes/app/organizations/shared/organization-details-card.tsx';
import EditOrganizationStatusForm from '@/routes/app/organizations/sponsors/single/edit-organization-status-form.tsx';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs.tsx';
import NewUserForm from '@/components/forms/new-user-form.tsx';
import useOrganizationStore from '@/routes/app/organizations/sponsors/single/store.ts';

export default function Sponsor() {
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const {
    documents,
    setDocuments,
    initializeOrganizations,
    organization,
    organizations,
    users,
  } = useOrganizationStore();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [addCollateralModalOpen, setAddCollateralModalOpen] = useState(false);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total_pages: 1,
  });

  const fetchDocData = async (page: number) => {
    const result = await sdk.getOrganizationDocuments(id!, {
      params: {
        page,
      },
    });
    setDocuments(result.data.documents);
    setPagination({
      page: result.data.page,
      total_pages: result.data.total_pages,
    });
  };

  useEffect(() => {
    initializeOrganizations(id!);
  }, []);

  useEffect(() => {
    fetchDocData(pagination.page);
  }, [pagination.page]);

  const onDocumentUploadSuccess = () => {
    fetchDocData(pagination.page);
  };

  if (!organization) {
    return <div>Loading...</div>;
  }

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= pagination.total_pages) {
      setPagination((prev) => ({ ...prev, page }));
    }
  };

  return (
    <div>
      <Dialog open={showStatusModal} onOpenChange={setShowStatusModal}>
        <DialogContent>
          <DialogTitle>Update Status</DialogTitle>
          <DialogDescription>
            Note: Changing an organization's status can and will affect the
            sending of emails for invites. Make sure you're aware of the changes
            you're making!
          </DialogDescription>
          <EditOrganizationStatusForm
            onClickCancel={() => setShowStatusModal(false)}
            onSuccess={() => initializeOrganizations(id!)}
            organization={organization}
          />
        </DialogContent>
      </Dialog>
      <div className="flex justify-between items-center gap-x-8 mb-4">
        <h1 className="text-3xl font-semibold">Sponsor: {organization.name}</h1>
        <Button
          variant="outline"
          size="sm"
          onClick={() => setShowStatusModal(true)}
        >
          Status: {organization.organization_status}
        </Button>
      </div>
      <Tabs defaultValue="details">
        <TabsList>
          <TabsTrigger value="details">Details</TabsTrigger>
          <TabsTrigger value="users">Users</TabsTrigger>
          <TabsTrigger value="documents">RAG Documents</TabsTrigger>
        </TabsList>
        <TabsContent className="w-full" value="details">
          <OrganizationDetailsCard organization={organization} />
        </TabsContent>
        <TabsContent value="users">
          <Card>
            <Dialog open={addUserModalOpen} onOpenChange={setAddUserModalOpen}>
              <DialogContent className="overflow-y-scroll max-h-screen">
                <DialogTitle className="hidden">Add User</DialogTitle>
                <DialogDescription className="hidden">
                  Adding user to organization
                </DialogDescription>
                <NewUserForm organizations={organizations} />
              </DialogContent>
            </Dialog>
            <div className="flex justify-between mb-4 items-center">
              <h3 className="font-semibold text-xl">All Users</h3>
              <Button onClick={() => setAddUserModalOpen(!addUserModalOpen)}>
                Add User
              </Button>
            </div>
            <DataTable
              columns={userColumns}
              data={users}
              navigationCallback={(data) => {
                navigate(`/app/users/${data.id}`);
              }}
            />
          </Card>
        </TabsContent>
        <TabsContent value="documents">
          <Card className="flex-1">
            <div className="flex justify-between mb-4 items-center">
              <h3 className="font-semibold text-xl">Sponsor RAGS</h3>
              <Button
                onClick={() =>
                  setAddCollateralModalOpen(!addCollateralModalOpen)
                }
              >
                Add Document
              </Button>
            </div>
            <Dialog
              open={addCollateralModalOpen}
              onOpenChange={setAddCollateralModalOpen}
            >
              <DialogContent className="overflow-y-scroll max-h-screen">
                <DialogTitle className="hidden">Add Document</DialogTitle>
                <DialogDescription className="hidden">
                  Adding document to RAG
                </DialogDescription>
                <CollateralForm
                  onSuccess={onDocumentUploadSuccess}
                  organizationId={organization.id}
                />
              </DialogContent>
            </Dialog>
            <DataTable
              columns={columns}
              data={documents}
              pagination={pagination}
              onPageChange={handlePageChange}
            />
          </Card>
        </TabsContent>
      </Tabs>
    </div>
  );
}
