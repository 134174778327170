import axios from 'axios';
import { IInvite, TOrganizationStatus } from '@/types.ts';

const baseURL = `${import.meta.env.VITE_API_BASE_URL}/api/v1`;
const headers = {
  'Content-Type': 'application/json',
  'X-CSRF-TOKEN': localStorage.getItem('csrf_access_token'),
};

const API = axios.create({
  baseURL,
  headers,
  withCredentials: true,
});

export default API;

export const sdk = {
  createOTPCode: async (email: string) => {
    const response = await API.post('/auth/otp', {
      email,
    });
    return response.data;
  },
  submitOTPCode: async (email: string, passcode: string) => {
    const response = await API.post('/auth/login', {
      email,
      passcode,
    });
    return response.data;
  },
  getInvites: (organizationId: string) => {
    return API.get(`/organizations/${organizationId}/invites`);
  },
  createInvites: (data: IInvite[], organization_id: string) => {
    return API.post('/invites/superuser', {
      organization_id,
      invites: data,
    });
  },
  checkInvitesForDuplicates: (data: IInvite[]) => {
    return API.post('/invites/dupe-check', {
      invites: data,
    });
  },
  getOrganizations: async (opts?: any) => {
    return API.get('/organizations', { ...opts });
  },
  getOrganization: async (id: string) => {
    return API.get(`/organizations/${id}`);
  },
  updateOrganization: async (id: string, data: any) => {
    const response = await API.put(`/organizations/${id}`, data);
    return response.data;
  },
  createOrganization: async (data: any) => {
    const response = await API.post('/organizations', data);
    return response.data;
  },
  updateOrganizationStatus: (
    id: string,
    data: { organization_status: TOrganizationStatus }
  ) => {
    return API.patch(`/organizations/${id}/status`, {
      ...data,
    });
  },
  getOrganizationDocuments: (id: string, opts?: any) => {
    return API.get(`/organizations/${id}/documents`, {
      ...opts,
    });
  },
  getOrganizationUsers: (id: string, opts: any = {}) => {
    return API.get(`/organizations/${id}/users`, { ...opts });
  },
  uploadOrganizationDocument: (organizationId: string, data: any) => {
    return API.post(`/organizations/${organizationId}/documents`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  patchOrganizationDocument: (
    organizationId: string,
    fileId: string,
    data: any
  ) => {
    return API.patch(
      `/organizations/${organizationId}/documents/${fileId}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  deleteOrganizationDocument: (
    organizationId: string,
    fileId: string,
    data: any
  ) => {
    return API.patch(
      `/organizations/${organizationId}/documents/${fileId}`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  createUser: async (data: any) => {
    return await API.post('/auth/register', data);
  },
  getUsers: async (opts: any) => {
    const response = await API.get('/users', { ...opts });
    return response.data;
  },
  getUser: async (id: string) => {
    const response = await API.get(`/users/${id}`);
    return response.data;
  },
  getThread: async (userId: string, statusActive = true, opts?: any) => {
    const response = await API.get(
      `/chat/threads/${userId}?active=${statusActive}`,
      { ...opts }
    );
    return response.data;
  },
  patchDeleteThread: async (userId: string) => {
    const response = await API.patch(`/chat/threads/${userId}`);
    return response.data;
  },
  downloadChatThread: async (userId: string, statusActive = true) => {
    const response = await API.get(
      `/chat/threads/${userId}/download?active=${statusActive}`,
      {
        responseType: 'blob',
      }
    );

    const { headers } = response;
    const contentDisposition = headers['content-disposition'];
    const fileName = contentDisposition.split(';')[1].split('=')[1].trim();
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  },
  downloadOrganizationFile: async (organizationId: string, fileId: string) => {
    const response = await API.get(
      `/organizations/${organizationId}/documents/${fileId}`,
      {
        responseType: 'blob',
      }
    );

    const { headers } = response;
    const contentDisposition = headers['content-disposition'];
    const fileName = contentDisposition.split(';')[1].split('=')[1].trim();
    const href = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  },
};
